<template>
    <basic-page class="contatti" :pagedata="this.pageData" bannerimg="/img/contatti-banner.jpg">
        <div class="container-beige intro container contatti">
           <div class="list">
                <div data-aos="fade-in-custom" class="fade-in-custom">
                    <p class="label title-col">{{ this.lang=='it' ? 'Sede Legale' : 'Registered Office'}}</p>
                    <div class="labval">
                        <p class="value">{{ this.lang=='it' ? 'Milano' : 'Milan'}}</p>
                        <p>Via dei Bossi 4</p>
                    </div>
                    <div>
                        
                        <br>
                        <p class="label">{{ this.lang=='it' ? 'P.IVA e Codice Fiscale' : 'VAT Number and Tax Code'}}</p>
                        <p>10881980964</p>
                    </div>
                </div>
                <div data-aos="fade-in-custom" class="fade-in-custom">
                    <p class="label title-col">{{ this.lang=='it' ? 'Sede Operativa' : 'Operational Headquarters'}}</p>
                    <div class="labval">
                        <p class="value">Trieste</p>
                        <p>Riva Tommaso Gulli 12</p>
                    </div>
                    
                    <div>
                        <br>
                        <p class="label">{{ this.lang=='it' ? 'Numero Telefonico' : 'Phone Number'}}</p>
                        <p><a href="tel:+39 040 9941001" style="text-decoration:none">+39 040 9941001</a></p>
                    </div>
                </div>
                <div data-aos="fade-in-custom" class="fade-in-custom">
                    <p class="label title-col">{{ this.lang=='it' ? 'Responsabile Relazioni Esterne' : 'External Relations Manager'}}</p>
                    <div class="labval">
                        <p class="value">Angelo Baiguera</p>
                        <p>The Action - Trieste</p>
                    </div>
                    <div>
                        <br>
                        <p class="label">{{ this.lang=='it' ? 'Per contattare l\'Ufficio Stampa' : 'Press Office contact'}}</p>
                        <!-- <p><a href="mailto:segreteria@theaction.it" style="text-decoration:none">segreteria@theaction.it</a></p> -->
                        <p><a href="mailto:ufficiostampa@polodelgusto.com" style="text-decoration:none">ufficiostampa@polodelgusto.com</a></p>
                    </div>
                </div>
            </div>
        </div>
    </basic-page>
</template>

<script>
import BasicPage from './BasicPage.vue'
import { fetchSinglePage, getTranslation } from '../libs/drupalClient'
export default {
    components: { BasicPage },
    name: 'contatti',
    data: () => {
        return {
            pageData: { attributes: {} },
            currentPageId: "7498ecb6-8aab-484e-9159-8509b2488a99"
        }
    },
    methods: {  
    },
    computed: {
        lang() {
            return this.$store.state.currentLang
        }
    },
    mounted() {
        fetchSinglePage(this.currentPageId, this.lang)
        .then(res => {
            this.pageData = res[0]
        }) 
    },
    watch: {
        lang() {
            getTranslation(this.$route.name, this.lang).then(res => {
                this.$router.push({path: res})
            })
            fetchSinglePage(this.currentPageId, this.lang)
            .then(res => {
                this.pageData = res[0]
            }) 
        }
    }
}
</script>

<style lang="scss" scoped>

@import "../styles/colors";

.contatti .list {
    align-items: stretch;
    justify-content: center;
    display: flex;
    &> div {
        display: block;
            align-items: flex-start;
            width: 30%;
        p {
            margin-left: 0;

            &.label {
                font-size: 1em;
                //margin-top: 20px;
            }
           
        }
         .title-col {
            margin-bottom: 2rem;
        }
    }
}


.container-beige {

    .list {
        >div {
            position: relative;
            &:first-child::before{
                display: none;
            }
            &:before {
                content: "";
                width: 1px;
                height: 100%;
                position: absolute;
                background-color:#dddddd ;
                left: 0;
                transform: translateX(-63px);
            }
            .label {
                color: $mainColor;
                font-size: 1.125rem;
            }
            .labval {
                height: 80px;
            }
            .value {
                color: $black;
                margin: 1rem 0rem ;
                margin-bottom: .5rem;
                font-size: 2.375rem;
                font-family: 'Cinzel';
                line-height: 2.4rem;
                
            }
        }
    }
    
}


@media (max-width:1500px) {

    .container-beige .list > div .value {
    font-size: 2rem;
}


        .container-beige {
        .list > div:before {
            transform: translateX(-38px);
        }
    }
}
@media (max-width:1200px) {
    .contatti .list > div {
        width: 30%;
    }

    .container-beige .list > div .labval {height: auto;}
}
@media (max-width:992px) {
    .contatti .list {
        flex-direction: column;
        padding:1rem 1rem 1rem 1rem;
        width: 80%;
        margin: 0 auto;
        &>div {
            width: auto;
            margin-bottom: 2rem;
            padding-bottom: 2rem;
            border-bottom: 1px solid #dddddd ;
            &:last-child{
                border-bottom: 0px;
            }
            &:before {
                display: none;
            }
            .title-col {
                margin-bottom:1rem;
            }
            p.label {
                text-align: left;
            }
            .value {
                margin: 1rem 0rem 0rem 0rem;
            }
        }
    }
}

@media (max-width:768px) {
    .contatti .list {
        width: 100%;
        &>div {
            margin-top: 0;
        }
    }
}


</style>